<template>
  <div
    class="card"
    @click="handleClick(blog.title)"
    :class="blog.photoSize"
    :key="blog.id"
    :style="`background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${blog.coverPhoto}); 
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;`"
  >
    <div class="edit-post" v-if="blogStore.editEnabled">
      <ion-icon name="trash-outline" @click="deletePost(blog.id)"></ion-icon>
      <ion-icon name="create-outline" @click="editPost(blog.title)"></ion-icon>
    </div>
    <h2 class="title">{{ blog.title }}</h2>
    <p class="subtitle">{{ blog.date }}</p>
  </div>
</template>

<script>
import { useBlogStore } from "@/stores/BlogStore";
import { useRouter } from "vue-router";
import { toKebabCase } from "../composables/helpers";

export default {
  props: ["blog"],
  data() {
    return {
      blogStore: useBlogStore(),
      router: useRouter(),
    };
  },
  methods: {
    handleClick(title) {
      if (!this.blogStore.editEnabled){
        this.router.push("/blogs/" + toKebabCase(title));
      }
    },
    deletePost(id) {
      this.blogStore.deletePost(id);
    },
    editPost(title) {
      this.router.push("/edit/" + toKebabCase(title));
    },
  },
};
</script>

<style>
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #353535;
  font-size: 3rem;
  color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  transition: all 500ms;
  overflow: hidden;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  text-align: center;
  position: relative;
}
.card:hover {
  box-shadow: rgba(2, 8, 20, 0.1) 0px 0.35em 1.175em,
    rgba(2, 8, 20, 0.08) 0px 0.175em 0.5em;
  transform: translateY(-3px) scale(1.02);
}
.card .title {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 800;
}
.card .subtitle {
  margin: 0;
  font-style: italic;
  font-size: 1rem;
}
ion-icon {
  font-size: 1.75rem;
}
@media (min-width: 600px) {
  .card-tall {
    grid-row: span 2 / auto;
  }
  .card-wide {
    grid-column: span 2 / auto;
  }
  .card-small {
    grid-column: span 1 / auto;
  }
  .card-large {
    grid-row: span 2 / auto;
    grid-column: span 2 / auto;
  }
}
.edit-post {
  display: flex;
  justify-content: space-between;
  width: 75px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 16px;
}
</style>
